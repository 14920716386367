<template>
    <defaultSec :title="'群组管理'" :isDisableScroll="true" :isFlexSection="true">
        <section class="scene-list">
            <el-scrollbar class="scene-left">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="群组状态" name="1">
                        <p v-for="item in sceneState" class="scene-left-item"
                           :class="formParam.status==item.value?'scene-left-item-on':''" @click="selectTripState(item)">
                            <span :style="{color: item.point}">● </span>{{item.name}}
                        </p>
                    </el-tab-pane>
                    <el-tab-pane label="群组目录" name="2">
                        <div style="display: flex;align-items: center">
                            <el-input size="mini" v-model="filterGroupName" placeholder="筛选目录"><i slot="prefix"
                                                                                                  class="el-input__icon el-icon-search"></i>
                            </el-input>
                            <el-button size="small" @click="addEditGroupItem(0)"
                                       style="color:#e85656;margin-left:10px;font-size: 18px"
                                       icon="el-icon-circle-plus-outline" type="text"></el-button>
                        </div>
                        <div class="custom-tree-node" style="padding: 0 2px 5px"
                             :class="formParam.groupId==''?'is-Current-node':''">
                            <div @click="tripGroupSel({},{id:''})">
                                <i class="el-icon-folder-opened" style="margin-right: 5px;color:#209e91"></i>
                                <p class="tree-name">所有</p>
                            </div>
                        </div>
                        <el-tree
                                ref="tree"
                                :data="treeData"
                                :filter-node-method="filterGroupNode">
                            <div class="custom-tree-node" :class="formParam.groupId==data.id?'is-Current-node':''"
                                 slot-scope="{ node, data }">
                                <div @click="tripGroupSel(node,data)">
                                    <i class="el-icon-folder-opened" :class="data.type==1?'icon-green':'icon-red'"
                                       style="margin-right: 5px"></i>
                                    <p class="tree-name">{{ data.groupName }}({{data.quantity}})</p>
                                </div>
                                <el-dropdown placement="bottom-start" class="drop-down"
                                             @command="(command)=>handleGroupCommand(command,data)" trigger="click">
                                    <span class="el-dropdown-link">
                                      <i class="icon-edit el-icon-more"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="edit">修改目录</el-dropdown-item>
                                        <el-dropdown-item command="del">删除目录</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </el-tree>
                    </el-tab-pane>
                </el-tabs>
            </el-scrollbar>
            <el-scrollbar class="scene-right">
                <div class="add-wrap">
                    <el-button type="danger" size="small" @click="handleClick(0)">+新建</el-button>
                </div>
                <div class="search-content">
                    <el-form :model="formParam" :inline="true" size="mini" class="demo-form-inline">
                        <el-form-item label="群组名称">
                            <el-input type="text" v-model="formParam.sceneName" placeholder="请输入群组名称"></el-input>
                        </el-form-item>
                        <el-form-item label="运行状态">
                            <el-select v-model="formParam.status" placeholder="请选择">
                                <el-option label="可用" :value="1"></el-option>
                                <el-option label="已停用" :value="0"></el-option>
                                <el-option label="全部" :value="-1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">查询</el-button>
                        </el-form-item>
                    </el-form>
                    <el-checkbox v-model="formParam.onlySelf" @change="changeFilter" size="mini" label="仅我创建"
                                 border></el-checkbox>

                </div>
                <tablePagination :total="0" :pageSize="formParam.pageSize" @handlePage="handlePage">
                    <el-table
                            v-loading="loading"
                            size="mini"
                            :data="sceneList"
                            border
                            style="width: 100%;margin-top: 20px;text-align: center">
                        <el-table-column type="index" fixed label="序号"></el-table-column>
                        <el-table-column
                                fixed
                                prop="id"
                                width="60"
                                label="标识">
                        </el-table-column>
                        <el-table-column
                                fixed
                                prop="sceneName"
                                label="群组名">
                          <template slot-scope="scope">
                            <span style="font-size: 14px;font-weight: 600;margin-right: 10px">{{scope.row.sceneName}}</span>
                            <el-popover
                                popper-class="popper-content"
                                placement="bottom-start"
                                width="200"
                                size="mini"
                                trigger="hover">
                              <h4 style="margin: 2px 0 20px">修改群组名</h4>
                              <el-input size="mini" type="text" v-model="scope.row.sceneName" placeholder="请输入群组名"></el-input>
                              <el-button size="mini" style="float: right;margin-top: 10px" type="danger" @click="saveSceneName(scope.row)">保存</el-button>
                              <el-button slot="reference" size="mini" type="text" icon="el-icon-edit"></el-button>
                            </el-popover>
                          </template>
                        </el-table-column>
                        <el-table-column
                                prop="count"
                                width="160"
                                label="预估人数(上次执行时间)">
                            <template slot-scope="scope">
                                <div style="font-size: 16px;font-weight: 600">{{scope.row.preUserCount || 0}}</div>
                                <div style="font-size: 12px;font-style: italic"><span
                                        :style="{color: scope.row.status==1?'#55a532':'#e50011'}">● </span>{{scope.row.sceneLastUpdateTime || scope.row.createTime}}

                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="更新方式"
                                width="100">
                            <template slot-scope="scope">
                                {{scope.row.sceneType == 1 ? '动态更新' : (scope.row.sceneType == 2 ? '定时更新' : '手动更新')}}

                            </template>
                        </el-table-column>
                        <!--<el-table-column-->
                                <!--prop="cronName"-->
                                <!--label="执行频率">-->
                        <!--</el-table-column>-->
                        <el-table-column
                                prop="createUserName"
                                label="创建者">
                        </el-table-column>
                        <el-table-column
                                prop="state"
                                width="80"
                                label="状态">
                            <template slot-scope="scope">
                                <el-tag size="mini" type="success" v-if="scope.row.status==1">运行中</el-tag>
                                <el-tag size="mini" type="danger" v-else>已停用</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="createTime"
                                label="创建时间">
                        </el-table-column>
                        <el-table-column
                                prop="remark"
                                label="备注信息">
                        </el-table-column>
                        <el-table-column
                                fixed="right"
                                label="操作"
                                width="120">
                            <template slot-scope="scope">
                                    <el-button @click="handleClick(scope.row.id)" type="text" size="small">查看</el-button>
                                    <el-dropdown style="margin-left: 16px" placement="bottom-start"
                                                 @command="handleGroupItem">
                                        <el-button type="text" class="el-dropdown-link">
                                            •••
                                        </el-button>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item :command="{state:'changeStatus',item:scope.row}">
                                                {{scope.row.status == 1 ? '禁用' : '启用'}}群组
                                            </el-dropdown-item>
                                            <el-dropdown-item :command="{state:'addGroup',item:scope.row}">添加至目录
                                            </el-dropdown-item>
                                            <el-dropdown-item :command="{state:'delGroup',item:scope.row}">删除群组
                                            </el-dropdown-item>
                                          <el-dropdown-item :command="{state:'copy',item:scope.row}">复制群组
                                          </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </tablePagination>
            </el-scrollbar>
            <el-dialog
                    title="新建/编辑目录"
                    :visible.sync="dialogVisible"
                    width="60%">
                <el-form label-width="80px">
                    <el-form-item label="目录名称">
                        <el-input clearable size="small" placeholder="请输入名称" v-model="selGroup.groupName"></el-input>
                    </el-form-item>
                    <el-form-item label="目录属性">
                        <el-select style="width: 100%" size="small" v-model="selGroup.type" placeholder="请选择属性">
                            <el-option :value="1" label="公共目录"></el-option>
                            <el-option :value="2" label="私有目录"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitGroupItem">确 定</el-button>
                </span>
            </el-dialog>
            <el-dialog
                    title="添加至目录"
                    @opened="openDialog"
                    :visible.sync="dialogAddRouteGroupVisible"
                    width="60%">
                <el-tree
                        ref="tree1"
                        show-checkbox
                        highlight-current
                        node-key="id"
                        check-on-click-node
                        :data="treeData">
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                        <div>
                            <i class="el-icon-folder-opened" style="margin-right: 5px"></i>
                            <p class="tree-name">{{ data.groupName }}</p>
                        </div>
                    </div>
                </el-tree>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogAddRouteGroupVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitRouteGroupItem">确 定</el-button>
                </span>
            </el-dialog>
        </section>

    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    let apiDone = null
    export default {
        name: "groupManage",
        data(){
            return {
                loading: false,
                total: 0,
                formParam: {
                    pageSize: 50,
                    currentPage: 1,
                    sceneName: '',
                    status: 1,
                    groupId: '',
                    onlySelf: false,
                },
                sceneList: [],
                //目录参数
                activeTab: '1',
                sceneState: [
                    {name: '所有群组', value: -1, point: '#55a532'},
                    {name: '运行中群组', value: 1, point: '#2dacd1'},
                    {name: '已停用群组', value: 0, point: '#e85656'},
                ],
                treeData: [],
                sceneRoute: 1,//1,群组；2：旅程
                groupList: [],
                dialogVisible: false,
                filterGroupName: '',
                selGroup: {},
                selScene: {},
                dialogAddRouteGroupVisible: false,
                destroyState:false
            }
        },
        components: {defaultSec, tablePagination},
        mounted() {
            this.initList()
            this.initGroupList()
        },
        watch: {
            filterGroupName(val) {
                console.log(val)
                this.$refs.tree.filter(val);
            },
            'sceneList.length'(val){
                console.log('length',val)
            },
            'formParam':{
                handler(newVal,oldVal){
                    console.log('form',newVal,oldVal)
                    // this.sceneList = []
                    // // this.formParam.currentPage = 1
                    // this.loading = true
                },
                deep: true

            }
        },
        //页面销毁之前终端数据循环
        beforeDestroy() {
            clearTimeout(apiDone)
            this.destroyState = true
        },
        methods: {
            initList() {
                this.sceneList = []
                this.formParam.currentPage = 1
                this.loading = true
                clearTimeout(apiDone)
                this.asyncInitTableData()
            },
            asyncInitTableData(){
                    this.$apiGet('lechun-cms/scene/getScenePageList', this.formParam).then(res => {
                        this.sceneList = [...this.sceneList,...res.list]
                        this.total = res.total
                        this.loading = false
                        if(!this.destroyState&&this.sceneList.length<this.total){
                            apiDone = setTimeout(()=>{
                                this.formParam.currentPage++
                                this.asyncInitTableData()
                            },100)

                        }
                    })
                // })

            },
            //初始化目录列表
            initGroupList(){
                this.$apiGet('lechun-cms/sceneRouteGroup/getGroupList', {
                    currentPage: 1,
                    pageSize: 10000,
                    sceneRoute: this.sceneRoute
                }).then(res => {
                    this.treeData = res.list
                })
            },
            //筛选旅程状态
            selectTripState(item){
                this.formParam.status = item.value
                this.initList()
            },
            //旅程目录选中
            tripGroupSel(node, data){
                this.formParam.groupId = data.id
                this.initList()
            },
            //旅程目录筛选
            filterGroupNode(value, data){
                if (!value) return true;
                return data.groupName.indexOf(value) !== -1
            },
            //旅程目录操作
            handleGroupCommand(command, data){
                //目录编辑
                if (command == 'edit') {
                    this.addEditGroupItem(data)
                }
                if (command == 'del') {
                    this.$confirm('此操作将删除该目录, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$apiGet('lechun-cms/sceneRouteGroup/delGroup', {id: data.id}).then(res => {
                            console.log(res)
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        })

                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                }
            },
            //添加编辑目录
            addEditGroupItem(item){
                this.dialogVisible = true
                if (item) {
                    this.selGroup = {
                        id: item.id,
                        groupName: item.groupName,
                        type: item.type || 1,
                        sceneRoute: this.sceneRoute
                    }
                } else {
                    this.selGroup = {
                        id: '',
                        groupName: '',
                        type: 1,
                        sceneRoute: this.sceneRoute
                    }
                }
            },
            //保存目录名称
            submitGroupItem(){
                this.$apiGet('lechun-cms/sceneRouteGroup/saveGroup', {...this.selGroup}).then(res => {
                    this.$message({
                        type: 'success',
                        message: '保存成功'
                    });
                    this.dialogVisible = false
                    this.initGroupList()
                })
            },
            //跳页执行
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            //是否筛选仅我创建
            changeFilter(){
                this.initList()
            },
            //修改某一群组(修改状态、删除)
            handleGroupItem(dataItem){
                console.log(dataItem)
                let dataInfo = dataItem.item
                //修改群组状态
                if (dataItem.state == 'changeStatus') {
                    this.$confirm('确定改变该群组状态吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$apiGet('lechun-cms/scene/changeStatus', {
                            'sceneId': dataInfo.id,
                            'status': (dataInfo.status == 1 ? 0 : 1)
                        }).then(res => {
                            this.$message({
                                type: 'success',
                                message: '更改成功'
                            });
                            this.initList()
                        })
                    })
                }
                //删除群组
                if (dataItem.state == 'delGroup') {
                    this.$confirm('确定删除选择的用户群组吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$apiGet('lechun-cms/scene/deleteScene', {sceneId: dataInfo.id}).then(res => {
                            this.$message({
                                showClose: true,
                                message: '成功',
                                type: 'success'
                            });
                            this.initList();
                        });
                    }).catch(() => {

                    });
                }
                //添加至目录
                if (dataItem.state == 'addGroup') {
                    this.selScene = dataInfo
                    this.dialogAddRouteGroupVisible = true
                }
                if(dataItem.state=='copy'){
                  this.$apiGet('lechun-cms/scene/copyUserGroup', {groupId:dataInfo.id}).then(res => {
                    this.$message({
                      showClose: true,
                      message: '复制成功',
                      type: 'success'
                    });
                    this.dialogAddRouteGroupVisible = false
                    this.initList();
                  });
                }
            },
            //打开添加至目录弹窗，初始化选中目录
            openDialog(){
                let treeKeys = this.selScene.groupId ? this.selRoute.groupId.split(',') : []
                this.$refs.tree1.setCheckedKeys(treeKeys)
            },
            //保存旅程添加目录
            submitRouteGroupItem(){
                let groupId = this.$refs.tree1.getCheckedKeys().join(',')
                let sceneId = this.selScene.id
                this.$apiGet('lechun-cms/sceneRouteGroup/saveSceneGroupRef', {sceneId, groupId}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '成功',
                        type: 'success'
                    });
                    this.dialogAddRouteGroupVisible = false
                    this.initList();
                });
            },
            onSubmit(){
                this.initList()
            },
            handleClick(id){
                this.$router.push({path: '/sceneDetail/:id', name: 'sceneDetail', params: {id: id}})
            },
            copyClick(id){
                this.$apiGet('lechun-cms/scene/copyUserGroup', {groupId:id}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '成功',
                        type: 'success'
                    });
                    this.dialogAddRouteGroupVisible = false
                    this.initList();
                });
            },
            saveSceneName(row){
              console.log(row);
              if(row.sceneName==''){
                this.$message({
                  type: 'error',
                  message: '请填写群组名'
                });
                return;
              }
              this.$apiGet('lechun-cms/scene/saveSceneName',row).then(res => {
                //console.log(res)
                this.$message({
                  message: '保存成功',
                  type: 'success'
                });

              })

            },
        },

    }
</script>

<style lang="scss" rel="stylesheet/scss">
    .scene-list {
        display: flex;
        height: 100%;
        .add-wrap {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 2px;
        }
        .search-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .scene-left {
            width: 140px;
            border-radius: 5px 0 0 5px;
            height: calc(100% - 20px);
            padding: 10px;
            background: #f5f5f5;
            font-size: 12px;
            box-shadow: 1px 0 6px 0 rgba(0, 0, 0, .26);
            position: relative;
            z-index: 9;
        }
        .scene-left-item {
            cursor: pointer;
        }
        .scene-left-item-on {
            background: rgba(32, 158, 145, .2);
        }
        .scene-right {
            flex: 1;
            height: calc(100% - 20px);
            background: #ffffff;
            border-radius: 0 5px 5px 0;
            padding: 10px 10px 10px 15px;
        }
        .copy-text {
            white-space: pre-line;
            margin: 0;
            line-height: 18px;
            background: #f2f2f2;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            padding: 6px;
            max-height: 120px;
            overflow: auto;
        }
        .el-tabs__item {
            font-size: 12px;
            padding: 0 9px;
        }
        .el-tree {
            background: transparent;
            font-size: 12px;
        }
        .custom-tree-node {
            flex: 1;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #606060;
            .tree-name {
                position: relative;
                top: 4px;
                display: inline-block;
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0;
                cursor: pointer;
            }
            .drop-down {
                font-size: 12px;
                color: #2dacd1;
                line-height: 24px;
            }
            .icon-edit {
                transform: rotate(90deg);
            }
            .icon-green {
                color: #55a532;
            }
            .icon-red {
                color: #e50011;
            }
        }
        .el-tree-node__content > .el-tree-node__expand-icon {
            padding: 1px;
            font-size: 0;
        }
        .is-Current-node {
            background: rgba(32, 158, 145, 0.2);
        }
        .el-dialog {
            .el-tree {
                font-size: 14px;
            }
            .tree-name {
                width: 100%;
                font-size: 14px;
            }
        }
        .el-dialog__body {
            padding-top: 20px;
        }
    }

</style>